







































import {
    Component,
    Vue
} from "vue-property-decorator";
import {
    Toast,
    Dialog
} from "vant";
import Mixin from "@/js/mixins";
import {
    href
} from '../../js/api/route';

@Component({
    mixins: [Mixin]
})
export default class BuyList extends Vue {
    public user_id = "";
    public list: any[] = []; // 商品列表
    public all_price = 0; // 商品总额
    public menuValue: any = ''; // 商品总额
    public menuTitle = '请选择商品套餐';
    public packAgeInfo: any[] = [];
    public packAgeList: any[] = [];

    public pageAge: any = {};
    public pageAgeAll: any = 0;
    public pageAgeNum: Number = 1;
    public pageAgeSetup: Number = 1;

    public address = ''

    // stepperPlus(e){
    //   if(e.number==e.buy_number){
    //     console.log(true)

    //   }
    // }
    onClick(name, title) {
        console.log(title);
        if (title == '套餐专区') {
            this.$nextTick(() => {
                setTimeout(() => {
                    const copy: any = this.$refs
                    copy.menuaaa.toggle()
                }, 0);
            })
        }
    }
    menuChange(e) {
        this.packAgeInfo.map(item => {
            if (e == item.package_id) {
                this.menuTitle = item.package_name
                this.pageAge = item.package_goods_list
                this.pageAgeAll = Number(item.package_price) * 100
                this.pageAgeSetup = Number(item.package_number)
                this.pageAgeNum = Number(item.package_number)
                // this.$set(this, this.pageAge, item);
                console.log(item)
            }
        })
    }
    minus(index) {
        let num = this.list[index].number
        let minNum = Number(this.list[index].min_number)
        console.log(num, minNum)
        if ((num - 1) < minNum) {
            this.$set(this.list[index], "number", 0);
        }
    }
    // 单个商品购买数量变化
    stepperChange(index: number) {
        let item: any = this.list[index];
        let price = item.number * item.buy_list.goods_price;
        this.$set(this.list[index], "all_price", price);
        this.setAllPrice();
    }
    // 计算总体价格
    setAllPrice() {
        let all_price = 0;
        this.list.forEach((item: any) => {
            all_price += item.all_price;
        });
        this.all_price = all_price * 100;
    }
    //套餐下单
    buyb() {
        let _this = this;
        let data = {
            user_id: this.user_id,
            package_id: this.menuValue,
            package_num: < number > this.pageAgeNum / < number > this.pageAgeSetup
        }
        this.$api.request({
            url: "common/buy/add-cart",
            data,
            success(res) {
                _this.$api.link("/buy/buy", {
                    ...res.data,
                    user_id: data.user_id
                });
                console.log()
            },
            fail(err) {
                if (err.data.code == 400 && err.data.message.indexOf('地址') != -1 && !_this.address) {
                    Dialog.confirm({
                            title: '提示',
                            message: '您还没有收款地址，请前往完善',
                        })
                        .then(() => {
                            href('/shop/addAddress?user_id=' + _this.user_id)

                        })
                        .catch(() => {
                            // on cancel
                        });
                }
            }
        })
    }
    // 下单
    buy() {
        let _this = this;
        let data: Dictionary < any > = {};
        let buy: any[] = [];
        data.user_id = this.user_id;
        this.list.forEach(item => {
            buy.push({
                goods_id: item.goods_id,
                goods_number: item.number
            });
        });
        data.buy = buy;
        this.$api.request({
            url: "common/buy/add-cart",
            data,
            success(res) {
                _this.$api.link("/buy/buy", {
                    ...res.data,
                    user_id: data.user_id
                });
            },
            fail(err) {
                if (err.data.code == 400 && err.data.message.indexOf('地址') != -1 && !_this.address) {
                    Dialog.confirm({
                            title: '提示',
                            message: '您还没有收款地址，请前往完善',
                        })
                        .then(() => {
                            href('/shop/addAddress?user_id=' + _this.user_id)

                        })
                        .catch(() => {
                            // on cancel
                        });
                }
            }
        });
    }
    // 获取所有商品信息
    getGoodsList() {
        let _this = this;
        this.$api.request({
            url: "goods/goods/goods-list",
            data: {
                user_id: _this.user_id
            },
            success(res) {
                //套餐信息
                _this.packAgeInfo = res.data.package
                _this.packAgeList = []
                _this.packAgeInfo.map(item => {
                    _this.packAgeList.push({
                        text: `${item.package_name}`,
                        value: item.package_id
                    })
                })
                _this.address = res.data.address.city

                let data = res.data.goods_list;
                data.map((item: any) => {
                    item.number = 0;
                    item.all_price = 0;
                });
                _this.list = data;
                if (!res.data.address) {
                    // _this.$toast('请填写收货地址')
                    Dialog.confirm({
                            title: '提示',
                            message: '您还没有收款地址，请前往完善',
                        })
                        .then(() => {
                            href('/shop/addAddress?user_id=' + _this.user_id)
                        })
                        .catch(() => {
                            // on cancel
                        });
                    // setTimeout(() => {
                    //     href('/shop/addAddress?user_id='+_this.user_id)
                    // }, 1000);
                }
            }
        });
    }

    init() {
        let user_id = this.$route.query.user_id;
        if (user_id) {
            this.user_id = String(user_id);
            this.list = [];
            this.all_price = 0;
            this.getGoodsList();
        } else {
            Toast({
                message: "参数出错",
                type: "fail",
                forbidClick: true,
                onClose: () => {
                    this.$api.goBack();
                }
            });
        }
    }
}
